import { useEffect } from "react";
import "./style.css";

export function Home() {

  useEffect(() => {
    window.location.href = "https://clientes.dcifood.com.br";
  });

  return (
    <div></div>
  );
}